<template>
<div>
  <p>Loging out...</p>
</div>
</template>

<script>
export default {
name: "logout"
}
</script>

<style scoped>

</style>